











































import { Vue, Component, Watch } from 'vue-property-decorator'
import { chatImportStore } from '@/store'

@Component({
  components: {
    BaseLinearProgress: () => import('@/components/UI/BaseLinearProgress.vue'),
    BaseBtn: () => import('@/components/UI/BaseBtn.vue'),
  },
})
export default class GenerationProgress extends Vue {
  get title () {
    return this.isHaveError
      ? this.action?.message ?? ''
      : this.$t('import.step3.generation.title').toString()
  }

  get description () {
    return this.isHaveError
      ? this.action?.body ?? ''
      : this.$t('import.step3.generation.description').toString()
  }

  get progress () {
    return chatImportStore.getters.generateProgress / 100
  }

  @Watch('progress')
  private watchProgress () {
    if (this.progress >= 1) this.$emit('finish')
  }

  get fileName () {
    return chatImportStore.state.fileName
  }

  get isHaveError () {
    return this.action?.hasError
  }

  get action () {
    return chatImportStore.getters.getProcessingActionByType('generate_chat')
  }

  private async cancel () {
    await chatImportStore.actions.removeArchive()
    this.$emit('repeat')
  }

  private mappingRepeat () {
    chatImportStore.actions.setParseState('need_mapping')
    this.$emit('previous')
  }
}
